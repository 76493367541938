(function(){"use strict";class c{constructor(){this.data={}}set(e,t){this.data[e]=t}get(e){return this.data[e]}}const r={Booster:class{constructor(e){this.options=e}log(e,t){const o=`
      display: inline-block;
      padding: 4px 6px;
      border-radius: 4px;
      line-height: 1.5em;
      color: #282735;
      background: linear-gradient(45deg,
        rgba(185, 205, 255, 0.4) 0%,
        rgba(201, 182, 255, 0.4) 33%,
        rgba(239, 184, 255, 0.4) 66%,
        rgba(255, 210, 177, 0.4) 100%);
        `,s=[`%c[${this.options.title}] ${e}. Link to documentation ${this.options.documentationLink}`,o];t?(console.group(...s),Array.isArray(t)?console.log(...t):console.log(t),console.groupEnd()):console.log(...s)}validate(e,t,o){if(!e.validate)return!0;if(typeof e.validate=="function"){if(!e.validate(o))return this.log(`Invalid value "${o}" for attribute "${t}"`),!1}else if(!e.validate.includes(o))return this.log(`Invalid value "${o}" for attribute "${t}"`,[`%cPossible values:%c
`+e.validate.map(s=>`• ${s}`).join(`
`),"font-weight: 700;","font-weight: initial;"]),!1;return!0}parse(e){const t=new c;for(const o in this.options.attributes){const s=this.options.attributes[o],a=e.getAttribute(o);if(!a){t.set(o,s.defaultValue);continue}if(!this.validate(s,o,a))continue;let n=a;s.parse&&(n=s.parse(a)??s.defaultValue),t.set(o,n)}this.options.apply.call(this,e,t)}getElements(){return document.querySelectorAll(`[${this.options.name}]`)}init(){this.getElements().forEach(t=>this.parse(t))}},parse:{stringToBoolean:i=>i!=="false"},validation:{isBoolean:i=>/^(true|false)$/.test(i),isNumber:i=>!isNaN(Number(i))}},d=new r.Booster({name:"fb-tabs",attributes:{"fb-tabs-speed":{defaultValue:5e3,validate:r.validation.isNumber,parse:Number}},apply(i,e){const t=i.querySelectorAll(".w-tab-link");if(!t.length)return this.log("Required attribute is missing");const o=e.get("fb-tabs-speed");let s;const a=()=>{s=setTimeout(()=>{var u;let n=Array.from(t).findIndex(b=>b.classList.contains("w--current"))+1;n>t.length-1&&(n=0),(u=t[n])==null||u.click()},o)};t.forEach(n=>n.addEventListener("click",()=>{clearTimeout(s),a()})),a()},title:"Auto Tab Rotation Booster",documentationLink:"https://www.flowbase.co/booster/webflow-auto-tab-rotation"}),l=()=>d.init();document.readyState==="complete"?l():window.addEventListener("load",l)})();
